import axios from 'axios';

export default (redirect = null) => ({
  formData: null,
  submitting: false,
  redirect: redirect,
  submit() {
    if (this.submitting) return;
    this.submitting = true;
    this.$el.style.pointerEvents = 'none';
    this.formData.dataCapture = true;
    console.log(this.formData);

    axios.get('/actions/sitemodule/preferences', {
      params: this.formData
    })
      .then(() => {
        if(this.redirect) {
          window.location = this.redirect;
        } else {
          this.submitting = false;
          this.$el.style.pointerEvents = 'auto';
          up.reload('#main-nav, #hub-blocks');
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  onMessage($event) {
    console.log($event.data);
    if(typeof $event.data === 'string' && $event.data.startsWith('zendesk-form:')) {
      // zendesk-form:ready
      // zendesk-form:pending
      // zendesk-form:success
      // zendesk-form:failed
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event' : $event.data
      });
    }
    if($event.data.type !== undefined && typeof $event.data.type === 'string' && $event.data.type == 'zendesk-form:dimensions') {
      this.$el.style.height = $event.data.data.height + 'px';
    }
    if($event.data.type !== undefined && typeof $event.data.type === 'string' && $event.data.type == 'zendesk-form:values') {
      console.log($event.data.data);
      this.formData = $event.data.data;
      this.submit();
    }
  },
  redirectUpdate(e) {
    if(!e.detail.length) {
      let orig = this.$el.dataset.origRedirect;
      console.log(orig);
      this.redirect = orig;
      this.$el.src = this.$el.src.replace(/\?redirect=(.*)\&/, `?redirect=${orig}&`);
    }
    else {
      let url = `${window.location.origin}${e.detail}`;
      this.redirect = url;
      this.$el.src = this.$el.src.replace(/\?redirect=(.*)\&/, `?redirect=${url}&`);
    }
  },
  init() {
    this.$el.setAttribute('scrolling', 'no');
    this.$el.style.height = '0px';
  }
});
