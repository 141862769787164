import axios from "axios";
import download from "downloadjs";

export default (sessionId) => {

  const cssClasses = {
    ready: 'bg-baby-yoda',
    start: 'bg-bert',
    warn: 'bg-olive-oyl'
  };

  return {

    downloadStatus: null,
    downloading: false,
    interval: null,
    sessionId: sessionId,
    url: null,

    init() {
      if(!this.sessionId) {
        this.getSessionId();
      }
      this.checkStatus();
      this.startInterval();
    },

    getSessionId() {
      axios.get('/actions/sitemodule/download/get-session-id')
        .then(resp => {
          if(resp.data.id) {
            this.sessionId = resp.data.id;
            this.checkStatus();
            this.startInterval();
          }
        });
    },

    startInterval() {
      if(!this.sessionId) return;
      this.interval = setInterval(() => {
        this.checkStatus();
      }, 10000);
    },

    getClass() {
      if(this.downloadStatus) {
        return cssClasses[this.downloadStatus];
      }
    },

    updateStatus(event) {
      console.log('status updated');
      clearInterval(this.interval);
      this.downloadStatus = event.detail.status;
      this.downloading = false;
      if(event.detail.url) this.url = event.detail.url;
      if(this.downloadStatus !== 'ready') {
        this.url = null;
        this.startInterval();
      }
    },

    checkStatus() {
      if(!this.sessionId) return;
      let details = { sessionId: this.sessionId };
      axios.get('/actions/sitemodule/download/check-status', { params: details })
        .then(resp => {
          if (resp.data.url) {
            this.url = resp.data.url;
            this.downloadStatus = 'ready';
            clearInterval(this.interval);
          } else if (resp.data.status === 'processing') {
            this.downloadStatus = 'start';
          }
        });
    },

    download() {
      if(!this.url || this.downloading) return;

     this.downloading = true;

      // Set as downloaded
      let details = { sessionId: this.sessionId, url: this.url };
      axios.get('/actions/sitemodule/download/complete-download', { params: details })
        .then(resp => {
          console.log('Download status updated');
        });

      // Download from URL
      let self = this;
      let x = new XMLHttpRequest();
      x.open( "GET", this.url , true);
      x.responseType="blob";
      x.onload= function(e){
        download(e.target.response, "zendesk-cx-trends-2023.pdf", "application/pdf");
        self.downloadStatus = null;
        self.downloading = false;
        self.$dispatch('download-complete');
      };
      x.send();
    }


  };
};
