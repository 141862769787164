/**
 * Usage:
 *
 * Step 1: Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import spine from "./scrollTo";
 *   Alpine.data('scrollTo', scrollTo);
 *   Alpine.start();
 *
 * Step 2: Somewhere in markup...
 *   <div x-data="scrollTo('selector')" />
 *
 * @param selector
 * @returns {{init(): void, el: null, selector: null}}
 */
export default (selector = null, opts = {}) => ({
  el: null,
  selector: selector,
  init() {

    if(!this.selector) return;

    this.el = document.querySelector(this.selector);
    if(!this.el) return;

    this.$el.addEventListener('click', (e) => {
      opts.preventDefault && e.preventDefault();
      this.el.scrollIntoView({
        behavior: "smooth"
      });
    });

    window.addEventListener('scroll', () => {
      this.hideEl();
    });
  },

  hideEl() {
    if(opts.hideOnScroll) {
      this.$el.style.opacity = 0;
    }
  }
});
