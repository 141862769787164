export default function() {
  return {

    fixed: false,
    header: null,

    init() {
      let header = document.getElementById('header');
      if(!header) return;

      window.addEventListener('scroll', () => { this.setFixed(); });
    },

    setFixed() {
      this.fixed = window.scrollY >= header.offsetHeight;
    }

  };
}
