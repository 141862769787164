import axios from "axios";

export default (pdfServiceBaseUrl, data = {}, regions = {}, emptyLabel = 'Select...') => ({

  formData: data,
  countries: [],
  countryOptions: '',

  downloading: false,
  processing: false,
  done: false,
  pdfServiceUrl: `${pdfServiceBaseUrl}`,

  init: async function() {
    this.formData.industry = data.industryExtended;
    if(this.formData.country === "other") this.formData.country = null;
    this.filterCountries(this.formData.region);
    this.getCountryOptions();

    console.log(this.formData);
    this.$el.addEventListener('submit', (e) => {
      e.preventDefault();
      this.submit();
    });
  },

  filterCountries(region) {
    this.countries = region ? regions[region] : [];
  },

  getCountryOptions() {
    let empty = "<option value=''>" + emptyLabel + "</option>";
    this.countryOptions = empty + this.countries
      .map(c => `<option value="${c.value}" ${this.formData.country === c.value ? 'selected' : ''}>${c.label}</option>`)
      .join();
  },

  changed(input) {
    this.$nextTick(() => {
      if(this.$refs[`select-${input}`].value !== "") {
        this.formData[input] = this.$refs[`select-${input}`].value;
        this.$refs[`input-${input}`].checked = true;
      }
      else {
        this.$refs[`input-${input}`].checked = false;
      }
      if(input === "country" && this.$refs['select-country'] === "other") {
        this.formData.country = null;
        this.$refs['input-country'].checked = false;
      }
      if(input === "region") {
        document.querySelector("[x-ref=input-country]").checked = false;
        this.formData.country = null;
      }
      this.filterCountries(this.formData.region);
      this.getCountryOptions();
    });
  },

  submit: async function() {
    this.done = false;

    if(this.processing) {
      return;
    }

    let pdfResponse;
    const formData = new FormData(this.$el);
    const self = this;

    // Get either already saved file, or URL for PDF gen (from DownloadController)
    const craftResponse = await axios.post(window.location.href, formData);

    if(craftResponse.data.url) {

      console.log(`URL: ${craftResponse.data.url}`);
      this.$dispatch('update-status', { status: 'start' });
      this.processing = true;

      // Trigger Netlify background PDF gen task, if browser request times out
      // This returns a 202 code, which currenlty causes Axios to throw an error
      await axios.post(`${this.pdfServiceUrl}`, craftResponse.data, {
        headers: {
          "Content-Type": "text/plain",
        }
      });
    }
    else {
      // Response is Servd asset URL
      this.$dispatch('update-status', { status: 'ready', url: craftResponse.data });
    }
  }

});
