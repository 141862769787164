/* Usage:
 *
 * Step 1: Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import { preferences } from "./preferences";
 *   Alpine.data('preferences', preferences);
 *   Alpine.start();
 *
 * Step 2: Somewhere in markup...
 *   <form x-data="preferences({})" />
 *
 * @param data
 * @returns {{init(): void, hasChanged: boolean, formData: {}, changed(): void}}
 */
export function preferences(data = {}, regions = {}, emptyLabel = 'Select...', industryGroup = 'industry') {

  const $form = this.$el;
  let formDataOrig = '';

  return {
    hasChanged: false,
    formData: data,
    countries: [],
    countryOptions: '',
    required: ['region', 'size'],

    init() {
      this.required.push(industryGroup);
      formDataOrig = JSON.stringify(this.formData);
      this.filterCountries(this.formData.region);
      this.getCountryOptions();
    },
    get valid() {
      return this.required.filter(r => this.formData[r] && this.formData[r].length).length === this.required.length;
    },
    filterCountries(region) {
      this.countries = region ? regions[region] : [];
    },
    getCountryOptions() {
      let empty = "<option value=''>" + emptyLabel + "</option>";
      this.countryOptions = empty + this.countries
        .map(c => `<option value="${c.value}" ${this.formData.country === c.value ? 'selected' : ''}>${c.label}</option>`)
        .join();
    },
    changed() {
      this.$nextTick(() => {
        this.hasChanged = formDataOrig != JSON.stringify(this.formData);
        if(this.hasChanged) {
          this.filterCountries(this.formData.region);
          this.getCountryOptions();
        }
      });
    }
  };
}
