export default function() {
  return {

    perc: 0,

    init() {
      window.addEventListener('scroll', () => { this.update(); });
      window.addEventListener('resize', () => { this.update(); });
    },

    update(e) {
      let max = document.body.clientHeight - window.innerHeight;
      this.perc = (window.scrollY / max) * 100;
    }

  };
}
