import Swiper, {Navigation, Autoplay, EffectFade, Pagination} from 'swiper';

export function videoCarousel(id = null) {
  let swiper = null;
  let swiperEl = null;

  if(!id) return;

  return {
    playing: false,
    videoShown: false,

    init() {
      let self = this;
      window._wq = window._wq || [];
      _wq.push({
        id: id,
        options: {
          videoFoam: true,
          playerColor: "17494D",
          smallPlayButton: true
        },
        onReady: function() {
          self.swiperInit();
        }
      });
    },

    swiperInit() {
      swiperEl = this.$refs.swiperEl || this.$root;
      swiper = new Swiper(swiperEl, {
        centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: 11,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        speed: 500,
        modules: [Autoplay, EffectFade],
      });
    },

    togglePlay() {
      let self = this;
      window._wq = window._wq || [];
      _wq.push({ id: id, onReady: function(video) {
        self.videoShown = true;
        self.playing ? video.pause() : video.play();
        self.playing = !self.playing;
        self.$refs.swiperEl.style.opacity = 0;
        self.$refs.swiperEl.style.zIndex = 0;
      }});
    }
  }
}

export function carousel(opts = {}) {
  let swiper = null;
  let swiperEl = null;

  return {
    movingIndexes: null,
    direction: null,
    textHeight: null,
    moving: false,
    currentIndex: 0,
    prevIndex: opts.count - 1,
    nextIndex: 1,

    activeClass(idx, elIdx) {
      let classNames = [];
      if(idx === elIdx) {
        classNames.push('active');
        classNames.push(`active-${this.direction}`);
      }
      if(this.movingIndexes && this.direction) {
        if (this.movingIndexes.includes(elIdx)) classNames.push(`moving-${this.direction}`);
      }
      return classNames.join(' ');
    },

    setTextHeight() {
      let texts = Array.from(this.$el.querySelectorAll('.carousel-text'));
      let maxHeight = Math.max(...texts.map(t => t.clientHeight));
      this.textHeight = `${maxHeight}px`;
    },

    init() {
      swiperEl = this.$refs.swiperEl || this.$root;
      swiper = new Swiper(swiperEl, {
        centeredSlides: true,
        loop: true,
        slidesPerView: 1,
        spaceBetween: 11,
        speed: 500,
        navigation: {
          nextEl: this.$root.querySelector('.swiper-button-next'),
          prevEl: this.$root.querySelector('.swiper-button-prev'),
        },
        pagination: {
          el: opts.pagination,
          type: 'bullets',
          bulletActiveClass: 'bg-tofu',
          bulletClass: 'border-tofu border-2 w-4 h-4 rounded-full inline-block m-1',
          clickable: true
        },
        breakpoints: {
          1024: {
            pagination: false,
          },
        },
        modules: [Pagination, Navigation],
      });

      this.setTextHeight();

      swiper.on('slideChange', () => {
        this.movingIndexes = [this.prevIndex, this.nextIndex];
        setTimeout(() => { this.movingIndexes = null; }, 500);
        this.currentIndex = swiper.realIndex;
        this.prevIndex = swiper.realIndex === 0 ? opts.count - 1 : swiper.realIndex - 1;
        this.nextIndex = swiper.realIndex === opts.count - 1 ? 0 : swiper.realIndex + 1;
      });

      swiper.on('slideChangeTransitionStart', () => {
        this.moving = true;
      });

      swiper.on('slideChangeTransitionEnd', () => {
        this.moving = false;
      });

      swiper.on('slideNextTransitionStart', () => {
        this.direction = 'next';
      });

      swiper.on('slidePrevTransitionStart', () => {
        this.direction = 'prev';
      });

      swiper.on('resize', () => {
        this.setTextHeight();
      });
    }
  };
}

export function signpostsCarousel(opts = {}) {
  let swiper = null;
  let swiperEl = null;

  return {
    init() {
      swiperEl = this.$refs.swiperEl || this.$root;
      swiper = new Swiper(swiperEl, {
        centeredSlides: false,
        loop: false,
        slidesPerView: 1.3,
        spaceBetween: 28,
        speed: 500,
        pagination: {
          el: opts.pagination,
          type: 'bullets',
          bulletActiveClass: 'bg-tofu',
          bulletClass: 'border-tofu border-2 w-4 h-4 rounded-full inline-block m-1',
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: 1.5
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 28,
            pagination: false,
          },
          1280: {
            slidesPerView: 3,
            spaceBetween: 52,
            pagination: false,
          }
        },
        modules: [Pagination],
      });
    }
  };
}

