export default () => {
  return {
    values: [], // Original scale values
    scaleValues: [], // Current scale values
    scaleMax: 2,// Max scale to start from
    scaleMin: 0.7,// Max scale to start from
    endAt: 0.1, // Fraction of window height from top to stop transform at e.g. 0.1 = 10% from top
    scalable: false, // Is scalable?
    windowHeight: null,
    endY: null,
    shapeY: null,
    pictureEl: null,
    picturePadding: null,

    transform() {
      if(this.scalable) {
        this.windowHeight = window.innerHeight;
        this.endY = Number(this.windowHeight * this.endAt).toFixed(4);
        this.shapeY = this.$refs.shape.getBoundingClientRect().top;
        this.setScale();
      }
    },

    setTranslate(width, height, xfr, yfr) {
      // Calculate translate/position
      return `translate(${(1 - xfr) * width} ${(1 - yfr) * height})`;
    },

    resetScale() {
      // Reset to original values
      //this.$refs.clipPath.attributes['transform'].value = `scale(${this.values.join(', ')})`;
      let scale = '';
      let translate = '';
      let width = this.$refs.shape.clientWidth;
      let height = this.$refs.shape.clientHeight;
      //this.scaleValues = this.values.map(t => Number(t * this.scaleMin));
      this.scaleValues[0] = Number(this.values[0] * this.scaleMin); // X
      this.scaleValues[1] = Number(this.values[1] * 1); // Y
      scale = `scale(${this.scaleValues.join(', ')})`;
      translate = this.setTranslate(width, height, this.scaleMin, 1);
      this.$refs.clipPath.attributes['transform'].value = `${translate} ${scale}`;
    },

    setScale() {
      let scale = '';
      let translate = '';
      let width = this.$refs.shape.clientWidth;
      let height = this.$refs.shape.clientHeight;
      let diffMax = this.windowHeight - this.endY;
      let remainingFr = (this.shapeY - this.endY) / diffMax;
      let scaleFr = this.scaleMin + ((this.scaleMax - 1) * remainingFr);
      let heightScaleFr = scaleFr;


      // Set new scale/translate values and write to SVG element
      if(this.shapeY > this.windowHeight || this.shapeY < this.endY) {
        this.scaleValues[0] = Number(this.values[0] * this.scaleMin); // X
        this.scaleValues[1] = Number(this.values[1] * 1); // Y
        translate = this.setTranslate(width, height, this.scaleMin, 1);
      }
      else {
        if(scaleFr < 1) {
          let padding = this.picturePadding.split('px')[0];
          let scaledPadding = padding * scaleFr;
          this.pictureEl.style.paddingBottom = `${padding * scaleFr}px`;
          heightScaleFr = 1;
        }
        this.scaleValues[0] = Number(this.values[0] * scaleFr); // X
        this.scaleValues[1] = Number(this.values[1] * heightScaleFr); // Y
        translate = this.setTranslate(width, height, scaleFr, heightScaleFr);
      }
      //this.scaleValues[0] = this.values[0].map(t => Number(t * scaleFr));
      scale = `scale(${this.scaleValues.join(', ')})`;
      this.$refs.clipPath.attributes['transform'].value = `${translate} ${scale}`;
    },

    init() {
      this.pictureEl = this.$el.querySelector('picture');
      this.picturePadding = window.getComputedStyle(this.pictureEl).paddingBottom;

      // Use clipPath's data-scale-to attribute if present
      let scaleMax = this.$refs.clipPath.dataset.scaleMax;
      this.scaleMax = scaleMax ? scaleMax : this.scaleMax;

      // Get SVG clipPath's original scale values
      let transformVal = this.$refs.clipPath.attributes['transform'].value;
      let scaleVal = transformVal.match(/scale\((.*)\)/)[1];

      // If scale values present, crack on
      if (scaleVal.length) {
        this.values = scaleVal.split(', ').map(v => Number(v));
        this.scaleValues = [...this.values];
        this.scalable = true;
        setTimeout(() => {
          this.setScale(this.scaleMax);
        }, 300);
      }
    }
  }
}
