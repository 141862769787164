import qs from 'qs';

export function salesUrlShare(baseUrl = '', regions) {
  function removeEmpty(obj) {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v != '')
        .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
    );
  }

  let formDataOrig = '';

  return {
    formData: {
      language : '',
      redirect : '',
      results: {},
      score: '',
      dataCapture: '',
      region : '',
      size : '',
      industry : '',
      utm_campaign : '',
      countryOptions: '',
    },
    countries: [],
    required: ['industry', 'region', 'size'],
    get valid() {
      return this.required.filter(r => this.formData[r].length).length === this.required.length;
    },
    get url() {
      let url = new URL(baseUrl);
      let reduced = removeEmpty(this.formData);
      console.log(reduced);
      url.search = qs.stringify(reduced, { skipNulls: true });
      return url;
    },
    init() {
      formDataOrig = JSON.stringify(this.formData);
      this.filterCountries(this.formData.region);
      this.getCountryOptions();
    },
    filterCountries(region) {
      if(region) {
        this.countries = regions[region];
      }
    },
    getCountryOptions() {
      let empty = "<option value=''>Select...</option>";
      this.countryOptions = empty + this.countries
        .map(c => `<option value="${c.value}" ${this.formData.country === c.value ? 'selected' : ''}>${c.label}</option>`)
        .join();
    },
    changed() {
      this.$nextTick(() => {
        this.hasChanged = formDataOrig != JSON.stringify(this.formData);
        if(this.hasChanged) {
          this.filterCountries(this.formData.region);
          this.getCountryOptions();
        }
      });
    }
  };
}
