/* CSS */
import "@js/parts/css";

/* JS */
import "@js/parts/lazyloading";
import "@js/parts/loadjs";
import "@js/parts/videos";
import "@js/parts/unpoly";
import benchmark from "@js/parts/alpine/benchmark";
import dataCapture from "@js/parts/alpine/dataCapture";
import shapeScroll from "@js/parts/alpine/shapeScroll";
import smoothscroll from "@js/parts/alpine/smoothscroll";
import { carousel, videoCarousel, signpostsCarousel } from "@js/parts/alpine/swiper";
import { preferences } from "@js/parts/alpine/preferences";
import { salesUrlShare } from '@js/parts/alpine/salesUrlShare';
import generate from '@js/parts/alpine/generate';
import toast from '@js/parts/alpine/toast';
import progress from '@js/parts/alpine/progress';
import stickTop from '@js/parts/alpine/stickTop';

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
import resize from '@aerni/alpine-resize';
import intersect from '@alpinejs/intersect';
import persist from '@alpinejs/persist';
Alpine.plugin(resize);
Alpine.plugin(intersect);
Alpine.plugin(persist);

window.Alpine = Alpine;
Alpine.data('benchmark', benchmark);
Alpine.data('carousel', carousel);
Alpine.data('videoCarousel', videoCarousel);
Alpine.data('signpostsCarousel', signpostsCarousel);
Alpine.data('preferences', preferences);
Alpine.data('dataCapture', dataCapture);
Alpine.data('shapeScroll', shapeScroll);
Alpine.data('smoothscroll', smoothscroll);
Alpine.data('salesUrlShare', salesUrlShare);
Alpine.data("generate", generate);
Alpine.data("toast", toast);
Alpine.data("progress", progress);
Alpine.data("stickTop", stickTop);
Alpine.start();
